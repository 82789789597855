import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormLabel,
  CRow,
  CHeaderDivider,
  CBreadcrumb,
  CBreadcrumbItem,
  CContainer,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilFolderOpen, cilInfo } from "@coreui/icons";
import "./TotalOutstanding.css";
import DataTable, { FilterComponent } from "react-data-table-component";
import { OutstandingList } from "../Helper/outstanding";
import { useSelector } from "react-redux";
import Select, { components } from "react-select";
import { customerListApi } from "../Helper/customer";
import { fetchCustomerListApi, fetchCustomerListApi1 } from "../Helper/order";


export default function TotalOutstanding() {
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState();
  const [perPage, setPerPage] = useState(10);
  const userData = useSelector((state) => state.userData);
  const [customerList, setCustomerList] = useState();
  const [listLoading, setListLoading] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [paidOption,setPaidOption]=useState("full paid");
  const [totalRecords,setTotalRecords]=useState(0);

  const columns = [
    {
      name: " No",
      selector: (row, index) => index + 1,
    },
    {
      name: "Customer Name",
      selector: (row) => row.customerName,
    },
    {
      name: "Customer Phone",
      selector: (row) => row.customerPhone,
    },

    {
      name: "Total Outstanding",
      selector: (row) => row.totalOutStanding,
    },
    {
      name: "Total Paid",
      selector: (row) => row.totalPaid,
    },
    {
      name: "Balance",
      selector: (row) => row.remainingAmount,
    },
    // {
    //   name: 'Type',
    //   selector: row => row.type,
    // },
    {
      name: "View",
      selector: (row) => (
        <>
          <Link to={"/outstandingViewList/" + row.customerId}>
            <CButton
              color="secondary"
              title="View Outstanding"
              variant="outline"
              className="px-0 buttonsOrderPage "
            >
              <CIcon icon={cilFolderOpen} size="lg" />
            </CButton>
          </Link>
        </>
      ),
    },
  ];

  let userId = userData.userinfo.userId;

  function handlePageChange(page) {
    setListLoading(true);
    var offset = (page - 1) * perPage;
    OutstandingList(userId, offset < 0 ? 0 : offset, selectedCustomer, paidOption).then(
      (res) => {
        if (res.status === 200) {
          setData(res.data);
          setTotalRows(res.totalRecords);
          setTotalRecords(res?.totalRecords);
          setListLoading(false);
        }
      },
      (err) => {
        console.log(err);
        setListLoading(false);
      }
    );
  }

  useEffect(() => {
    handlePageChange(0);
  }, []);

  useEffect(() => {
    if (inputValue !== "") {
      setTimeout(() => {
        getCutomerlist();
      }, 200);
    }
  }, [inputValue])

  useEffect(() => {
    getCutomerlist()
  }, [])

  const paginationComponentOptions = {
    rowsPerPageText: "",
    noRowsPerPage: true,
  };

  const getCutomerlist = () => {
    fetchCustomerListApi1(inputValue).then((res) => {
      var result = res?.data;
      var customerLive = [];
      result.forEach((element) => {
        customerLive.push({
          value: element.customerId,
          label: element.customerName,
        });
      });
      setCustomerList(customerLive);
    });
  };

  const handleCustomer = (e) => {
    setSelectedCustomer(e.value);
  };

  const handleInputChange = (value) => {
    setInputValue(value)
  }

  const handlePayment = (e) => {
    setPaidOption(e.target.value);
  };

  const handleSearch = async (page) => {
    setListLoading(true);
    
    OutstandingList(userId, 0, selectedCustomer, paidOption).then((res) => {
      setData(res?.data);
      setListLoading(false);
      setTotalRecords(res?.totalRecords);
    });
  };

  const handleClear = (e) => {
    e.preventDefault();
    window.location.reload();
  };
  const customStyles = {
    cells: {
      style: {
        borderRight: "1px solid #ddd",
        borderLeft: "1px solid #ddd"
      },
    },
    rdt_TableHeadRow: {
      style: {
        borderRight: "1px solid #ddd",
        borderLeft: "1px solid #ddd"
      },
    }
  };


  return (
    <div>
      <CHeaderDivider />
      <CContainer fluid>
        <CRow>
          <CCol xs={12}>
            <h5 className="main-title">Outstanding </h5>
          </CCol>
          <CCol xs={8}>
            <CBreadcrumb
              className="m-0 ms-2"
              style={{ "--cui-breadcrumb-divider": "'>'" }}
            >
              <CBreadcrumbItem>
                {" "}
                <Link to="/dashboard">Home</Link>
              </CBreadcrumbItem>
              <CBreadcrumbItem actives>Total Outstanding</CBreadcrumbItem>
            </CBreadcrumb>
          </CCol>
          <CCol xs={4}></CCol>
        </CRow>
      </CContainer>

      <CRow className="justify-content-center">
        <CCol md={12} lg={12} sm={12}>
          <CCard style={{ margin: "10px" }} className="mb-4">
            <CCardHeader>
              <strong>Total Outstanding</strong>
            </CCardHeader>
            <CCardBody>
              <CRow className="">
                <CCol md={12}>
                  <CFormLabel>
                    <b>Filter:</b>
                  </CFormLabel>
                </CCol>

                <CCol md={3}>
                  <CFormLabel>Customer Name</CFormLabel>
                  <Select
                    options={customerList}
                    placeholder={
                      <div className="select-placeholder-text">
                        Select Customer
                      </div>
                    }
                    className="text-start mb-3"
                    value={customerList?.find(
                      (obj) => obj.value === selectedCustomer
                    )}
                    onChange={handleCustomer}
                    onInputChange={handleInputChange}
                  />
                </CCol>

                <CCol md={3}>
                  <CFormLabel>Payment</CFormLabel>
                  <select
                    name="cars"
                    id="cars"
                    className="form-select"
                    onChange={handlePayment}
                  >
                    <option value="full paid">Paid</option>
                    <option value="unpaid">Unpaid</option>
                    <option value="partial paid">Partially Paid</option>
                  </select>
                </CCol>
                <br />
                <CCol md={12}>
                  <div className="text-center mt-2">
                    <CButton
                      color="success"
                      className="m-2 text-white"
                      onClick={handleSearch}
                    >
                      Search
                    </CButton>
                    <CButton
                      color="dark"
                      className="text-white"
                      onClick={handleClear}
                    >
                      Clear
                    </CButton>
                  </div>
                </CCol>
              </CRow>

              {/* <DataTable
                className=" mt-3 "
                columns={columns}
                data={data}
                highlightOnHover
                pagination
                paginationServer
                progressPending={listLoading}
                paginationRowsPerPageOptions={[]}
                paginationComponentOptions={paginationComponentOptions}
                paginationTotalRows={totalRecords}
                onChangePage={handlePageChange}
                customStyles={customStyles}
              /> */}

              <DataTable
                className="tableTopSpace "
                columns={columns}
                responsive={true}
                data={data}
                progressPending={listLoading}
                paginationTotalRows={totalRecords}
                onChangePage={handlePageChange}
                pagination
                paginationComponentOptions={paginationComponentOptions}
                customStyles={customStyles}
                paginationServer
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  );
}

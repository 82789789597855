import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import "./BillStyles.css"
import { getOrderPrint } from '../Helper/order';

const PrintBill = () => {

 const [data, setData] = useState();
 const { Id } = useParams();

 useEffect(() => {
  getOrderPrint(Id).then((res) => {
   if (res.status === 200) {
    setData(res.data)
   }
   setTimeout(() => {
    window.print()
   }, 1000)
  })
 }, []);


 return (
  <div>
   <div className="ticket " style={{ fontFamily: "Times New Roman", fontSize: "9px", minWidth: "219.21259843" }} >
    <div className="text-center">
     <strong style={{ textAlign: "center", marginBlock: "5px", fontSize: "12px" }}>CP LASER SOLDERING</strong>
    </div>
    <hr className="m-0" style={{ borderTop: "10px solid !important" }} />
    Order Id : <b className="">{data?.orderId} </b><br />
    Order Date : <b className="">{data?.orderDate} </b>
    <hr className="m-0" style={{ borderTop: "10px dotted !important" }} />
    <span style={{ margin: "2px", padding: "0" }}>Name : <b className=""> {data?.customerName} </b></span>
    <br /><span style={{ margin: "2px", padding: "0" }}>Contact No. : <b className="">{data?.customerPhone ?? ""} </b></span>
    <hr className="m-0" style={{ borderTop: "10px dotted !important" }} />
    <table className="table" style={{ width: "100%" }}>
     <tr>
      <td className='text-center' colSpan={4}>........................................................................................</td>
     </tr>
     <tr>
      <th scope="col" className="quantity" style={{ textAlign: "left" }}>Sr.</th>
      <th scope="col" className="description" style={{ textAlign: "left" }}>Items</th>
      <th scope="col" className="description" style={{ textAlign: "left" }}>Type</th>
      <th scope="col" className="price" style={{ textAlign: "left" }}>₹</th>
     </tr>
     <tr>
      <td className='text-center' colSpan={4}>........................................................................................</td>
     </tr>

     {data?.orderItems?.map((data, index) => {
      return (
       <tr key={index}>
        <td className="quantity">{index + 1}</td>
        <td className="description" style={{ textTransform: "capitalize" }}>{data.item}</td>
        <td className="description" style={{ textTransform: "capitalize" }}>{data.type}</td>
        <td className="">{data.charges}</td>
       </tr>
      )
     })}
     <tr>
      <td className='text-center' colSpan={4}>........................................................................................</td>
     </tr>

     <tr>
      <td className="quantity"></td>
      <td className="quantity"></td>
      <td className="description">Total</td>
      <td className="price me-0">{data?.totalCharges}</td>
     </tr>
    </table>
    ........................................................................................            <br />
    <b className="">Previous Outstanding </b><br /><br />
    <table className='table' style={{ width: "100%" }}>
     <tr>
      <td className="quantity">Sr</td>
      <td className="quantity">OID</td>
      <td className="description"></td>
      <td className="price">₹</td>
     </tr>
     <tr>
      <td className='text-center' colSpan={4}>........................................................................................</td>
     </tr>
     {
      data?.preOrderArray?.map((data, index) => {
       return <>

        <tr>
         <td className="quantity">{index + 1}</td>
         <td className="quantity">{data.orderId}</td>
         <td className="description">R.AMOUNT</td>
         <td className="price">{data.remainingAmount}</td>
        </tr>
        <tr>
         <td className='text-center' colSpan={4}>........................................................................................</td>
        </tr>
       </>
      })
     }
     <tr>
      <td className="quantity"></td>
      <td className="quantity"></td>
      <td className="description">G.TOTAL</td>
      <td className="price">{data?.preOrderTotalCharges}</td>
     </tr>
     <tr>
      <td className='text-center' colSpan={4}>........................................................................................</td>
     </tr>
     <tr>
      <td className='text-center' colSpan={4}>Thanks for Purchase !</td>
     </tr>
    </table>
   </div>
  </div>
 )
}

export default PrintBill;
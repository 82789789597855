import React, { useState, useEffect } from "react";
import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CHeaderDivider,
    CDropdown,
    CDropdownToggle,
    CDropdownMenu,
    CDropdownItem,
    CContainer,
} from "@coreui/react";
import {
    cilFile,
    cilLibraryAdd,
    cilSettings,
} from "@coreui/icons";
import { dashbordApi } from "../Helper/dashbord";
import CIcon from "@coreui/icons-react";
import { cilPencil, cilTrash, cilFolderOpen } from "@coreui/icons";
import DataTable from "react-data-table-component";
import "./dashbord.css";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { customerApi } from "../Helper/customer"
import swal from "sweetalert";
import {
    orderListApi,
    deleteorderByIdApi,
} from "../Helper/order";

const customStyles = {
    cells: {
        style: {
            borderRight: "1px solid #ddd",
        },
    },
};


export default function Dashboard() {
    const [customers, setcustomers] = useState();
    const [validated, setValidated] = useState(false);
    const [orders, setorders] = useState();
    const [sentOrders, setSendOrders] = useState();
    const [todaysOrders, settodaysOrders] = useState();
    const [orderList, setorderList] = useState([]);
    const [data, setData] = useState();
    const [orderTotalRecord, setorderTotalRecord] = useState();
    const [perPage, setPerPage] = useState(10);
    const [listLoading, setListLoading] = useState(false);
    const [name, setName] = useState();
    const [contact, setContact] = useState();
    const [customerPhoneError, setcustomerPhoneError] = useState(false)
    const [unpaidBill, setUnpaidBill] = useState();
    const [todaysCollection, setTodaysCollection] = useState();
    const [todaysTotalCollection, settodaysTotalCollections] = useState();
    const userData = useSelector((state) => state.userData);
    let userId = userData.userinfo.userId;
    let navigate = useNavigate();


    const styleheader = {
        marginTop: "-24px",
        marginLeft: "-25px",
        marginRight: "-25px"
    }

    const customStyles = {
        cells: {
            style: {
                borderRight: "1px solid #ddd",
            },
        },
    };

    const deleteorder = async (orderId) => {
        let payloadData = {
            orderId: orderId,
        };
        swal({
            title: "Are you sure?",
            text: "Are you sure to delete Order?",
            icon: "warning",
            dangerMode: true,
            buttons: ["Cancel", "Ok"],
        }).then((willDelete) => {
            if (willDelete) {
                deleteorderByIdApi(payloadData).then((res) => {
                    if (res.status === 200) {
                        swal("Success", res.message, "success").then((ok) => {
                            if (ok) {
                                window.location.reload();
                            }
                        });
                    }
                });
            }
        });
    };

    const columns = [
        {
            name: " No",
            selector: (row, index) => index + 1,
            width: "50px"
        },
        {
            name: "Actions",
            allowOverflow: true,
            wrap: true,
            width: "150px",
            selector: (row) => (
                <>
                    <div className="button-customerorder">

                        <CButton
                            color="success"
                            variant="outline"
                            title="Add Outstanding"
                            style={{ marginRight: '8px', marginLeft: '0px !important' }}
                            className="ms-2 buttonsOrderPage w-100"
                            onClick={() =>
                                navigate("/customerbilling/actionoutstanding", {
                                    state: {
                                        orderId: row.orderId,
                                        orderDate: row.orderDate,
                                        customerId: row.customerId,
                                        customerName: row.customerName,
                                    },
                                })
                            }
                        >
                            <CIcon icon={cilLibraryAdd} size="lg" />
                        </CButton>
                        <CDropdown variant="btn-group" direction="dropup-center" >
                            <CDropdownToggle color="dark" className="btn-md"> <CIcon icon={cilSettings} size="lg" /></CDropdownToggle>
                            <CDropdownMenu className="topdropshow">
                                <Link to={"/order/" + row.orderId} className=" text-decoration-none" >
                                    <CDropdownItem >
                                        <CButton
                                            color="dark"
                                            title="Edit Order"
                                            variant="outline"
                                            className="px-0 buttonsOrderPage "
                                        >
                                            <CIcon icon={cilPencil} size="lg" className="" />
                                        </CButton>
                                        <span className="ms-3 text-decoration-none text-dark" >Edit </span>
                                    </CDropdownItem>
                                </Link>
                                <Link to={"/view/" + row.orderId} className=" text-decoration-none">
                                    <CDropdownItem>
                                        <CButton
                                            color="secondary"
                                            title="View Order"
                                            variant="outline"
                                            className="px-0 buttonsOrderPage "
                                        >
                                            <CIcon icon={cilFolderOpen} size="lg" />
                                        </CButton>
                                        <span className="ms-3 text-decoration-none text-dark" >View</span>
                                    </CDropdownItem>
                                </Link>
                                <Link to={"https://neotesting.online/cplasersoldering/testing/opr/" + row.orderId} className=" text-decoration-none" target="_blank" >
                                    <CDropdownItem >
                                        <CButton
                                            color="dark"
                                            title="Print"
                                            variant="outline"
                                            className="px-0 buttonsOrderPage "
                                        >
                                            <CIcon icon={cilFile} size="lg" className="" />
                                        </CButton>
                                        <span className="ms-3 text-decoration-none text-dark" >Print</span>
                                    </CDropdownItem>
                                </Link>
                                <CDropdownItem href="#" onClick={() => deleteorder(row.orderId)}>
                                    <CButton
                                        color="danger"
                                        title="Delete Order "
                                        variant="outline"
                                        className="px-0 buttonsOrderPage "
                                    >
                                        <CIcon icon={cilTrash} size="lg" />
                                    </CButton>
                                    <span className="ms-3">Delete</span>
                                </CDropdownItem>
                            </CDropdownMenu>
                        </CDropdown>
                    </div>
                </>
            ),
        },
        {
            name: "Order No",
            selector: (row) => row.orderId,
            width: "85px"
        },
        {
            name: "Customer Name",
            wrap: true,
            selector: (row) =>
                row.customerName + " (Ph. " + row.customerPhone + ")"
            ,
        },
        // {
        //   name: "Customer Phone",
        //   selector: (row) => row.customerPhone,
        //   width: "125px"
        // },
        {
            name: "Complete",
            selector: (row) =>
            (
                <>
                    {row.isComplete == "1" ? (
                        <>
                            <span
                                className="badge p-2"
                                style={{
                                    backgroundColor: "lightgreen",
                                    color: "#006400",
                                    fontSize: "0.75rem",
                                }}
                            >
                                Yes
                            </span>
                        </>
                    ) : (
                        <span
                            className="badge p-2"
                            style={{
                                backgroundColor: "lightpink",
                                color: "red",
                                fontSize: "0.75rem",
                            }}
                        >
                            No
                        </span>
                    )}
                    &nbsp;
                </>
            ),
            width: "90px"
        },
        {
            name: "Sent",
            selector: (row) =>
            (
                <>
                    {row.isSent == "1" ? (
                        <>
                            <span
                                className="badge p-2"
                                style={{
                                    backgroundColor: "lightgreen",
                                    color: "#006400",
                                    fontSize: "0.75rem",
                                }}
                            >
                                Yes
                            </span>
                        </>
                    ) : (
                        <span
                            className="badge p-2"
                            style={{
                                backgroundColor: "lightpink",
                                color: "red",
                                fontSize: "0.75rem",
                            }}
                        >
                            No
                        </span>
                    )}
                    &nbsp;
                </>
            ),
            width: "90px"
        },
        {
            name: "Order Date",
            wrap: true,
            selector: (row) => row.orderDate,
            width: "105px"
        },
        {
            name: "Total Charges",
            selector: (row) => row.totalCharges,
            width: "110px"
        },
    ];

    const Dashboardcard = () => {
        dashbordApi(userData.userinfo.userId)
            .then(
                async (res) => {
                    setcustomers(res.data.customers);
                    setorders(res.data.orders);
                    settodaysOrders(res.data.todaysOrders);
                    setUnpaidBill(res.data.customerWithoutBill);
                    setSendOrders(res.data.sentOrders);
                    setTodaysCollection(res.data.todaysPaidAmount);
                    settodaysTotalCollections(res?.data?.todaysTotal);
                },
                (err) => { }
            ).catch();
    };

    let regx = /^[A-Za-z\s]*$/;

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
        } else {
            event.stopPropagation();
            event.preventDefault();
            // if (regx.test(name) && contact.length === 10) {
            if (contact.length === 10) {
                let payloadData = {
                    userId: userData.userinfo.userId,
                    customerName: name,
                    customerPhone: contact,
                };
                customerApi(payloadData).then((res) => {
                    // console.log(res.status)
                    if (res.status === 200) {
                        swal("Success", res.message, "success").then((Ok) => {
                            if (Ok) {
                                window.location.reload();
                            }
                        })
                    } else {
                        swal("Error", res.message, "warning")
                    }
                }, (err) => {
                    // console.log(err)
                })
            } else {
                if (contact.length != 10) {
                    setcustomerPhoneError(true)
                }
            }
        }
        setValidated(true);
    };

    const validationForm = (inputName, value) => {
        if (inputName == "number" && value && value.length != 10) {
            setcustomerPhoneError(true)
            setValidated(false);
            return false;
        } else {
            setcustomerPhoneError(false)
        }
        setValidated(true);
    }

    const paginationComponentOptions = {
        rowsPerPageText: "",
        noRowsPerPage: true,
    };

    // Dashbord Table Api 
    const unsentOrdersData = async (page) => {
        setListLoading(true)
        await orderListApi(
            userData.userinfo.userId,
            page ? page : 0,
            "",
            "",
            "",
            "0"
        )
            .then(
                async (res) => {
                    console.log("response:", res.data)
                    setorderList(res.data);
                    setData(res.data);
                    setListLoading(false);
                    setorderTotalRecord(res.totalRecords);
                },
                (err) => {
                    setListLoading(false);
                }
            )
            .catch(setListLoading(false));

    };

    const handlePageChange = (page) => {
        let currentPage = page;
        var offset = (currentPage - 1) * perPage;
        unsentOrdersData(offset);
    };


    useEffect(() => {
        // getcustomer()
        Dashboardcard();
        unsentOrdersData(0)
    }, []);


    return (
        <div>
            <CHeaderDivider />
            <CContainer fluid>
                <CRow>
                    <CCol xs={9}>
                        <h5 className="main-title"> Dashboard </h5>
                    </CCol>
                    <CCol xs={3}>
                        <div className="text-end">
                            <Link to="/order">
                                <CButton
                                    color="info"
                                    size="sm"
                                    className="text-end text-white"
                                    type="button"

                                >
                                    + Order
                                </CButton>
                            </Link>
                        </div>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol sm={6} md={4} lg={3} className="mt-3">
                        <div className="rounded-4 mt-3" style={{ background: "lightblue" }}>
                            <div class="p-3">
                                <strong class="text-primary  mb-5" style={{ fontSize: "1.0rem" }}>Customers</strong>
                                <div className="d-flex flex-column justify-content-center align-items-start pt-3">
                                    <h2 className="text-primary">{customers}</h2>
                                    <Link to="/customer" className="text-primary text-decoration-none">
                                        <span class="fw-bold underline">View</span>
                                        <i className="bi bi-arrow-right-short ms-2"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </CCol>
                    <CCol sm={6} md={4} lg={3} className="mt-3">
                        <div className="rounded-4 mt-3" style={{ background: "#eebfdd" }}>
                            <div class="p-3">
                                <strong class=" mb-5" style={{ color: "#be1e84", fontSize: "1.0rem" }}>Total Orders</strong>
                                <div className="d-flex flex-column justify-content-center align-items-start pt-3">
                                    <h2 className="" style={{ color: "#be1e84" }} >{orders}</h2>
                                    <Link to="/customerbilling" style={{ color: "#be1e84" }} className="text-decoration-none">
                                        <span class="fw-bold underline">View</span>
                                        <i className="bi bi-arrow-right-short ms-2"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </CCol>
                    <CCol sm={6} md={4} lg={3} className="mt-3">
                        <div className="rounded-4 mt-3 " style={{ background: "#bfeebf" }}>
                            <div class="p-3">
                                <strong class=" mb-5 text-success" style={{ fontSize: "1.0rem" }}>Sent Orders</strong>
                                <div className="d-flex flex-column justify-content-center align-items-start pt-3">
                                    <h2 className="text-success" >{sentOrders}</h2>
                                    <Link to="/customerbilling" style={{ color: "#2a872a" }} className="text-decoration-none">
                                        <span class="fw-bold text-success underline">View</span>
                                        <i className="bi bi-arrow-right-short ms-2"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </CCol>
                    <CCol sm={6} md={4} lg={3} className="mt-3">
                        <div className="mt-3 rounded-4 " style={{ background: "lemonchiffon" }}>
                            <div class="p-3">
                                <strong class=" mb-5" style={{ color: "darkorange", fontSize: "1.0rem" }}>Today's Orders</strong>
                                <div className="d-flex flex-column justify-content-center align-items-start pt-3">
                                    <h2 className="" style={{ color: "darkorange" }}>{todaysOrders}</h2>
                                    <Link to="/customerbilling" style={{ color: "darkorange" }} className=" text-decoration-none">
                                        <span class="fw-bold underline">View</span>
                                        <i className="bi bi-arrow-right-short ms-2"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </CCol>
                    <CCol sm={6} md={4} lg={3} className="mt-3">
                        <div className="mt-3 rounded-4" style={{ background: "floralwhite" }}>
                            <div class="p-3">
                                <strong class=" mb-5 text-danger" style={{ fontSize: "1.0rem" }}>Unpaid Bills</strong>
                                <div className="d-flex flex-column justify-content-center align-items-start pt-3">
                                    <h2 className="text-danger" >{unpaidBill}</h2>
                                    <Link to="/totaloutstanding" className="text-danger text-decoration-none">
                                        <span class="fw-bold underline">View</span>
                                        <i className="bi bi-arrow-right-short ms-2"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </CCol>
                    <CCol sm={6} md={4} lg={3} className="mt-3">
                        <div className="mt-3 rounded-4" style={{ background: "rgb(187 212 227)" }}>
                            <div class="p-3">
                                <strong class=" mb-5" style={{ fontSize: "1.0rem" }}>Todays Collection</strong>
                                <div className="d-flex flex-column justify-content-center align-items-start pt-3">
                                    <h2 className="" >{todaysCollection}</h2>
                                    <Link to="/totaloutstanding" className=" text-decoration-none">
                                        <span class="fw-bold"></span>
                                        <i className="bi bi-arrow-right-short ms-2"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </CCol>
                    <CCol sm={6} md={4} lg={3} className="mt-3">
                        <div className="mt-3 rounded-4" style={{ background: "#EEE8CC" }}>
                            <div class="p-3">
                                <strong class=" mb-5" style={{ fontSize: "1.0rem", color: "#B59104" }}>Todays Total Collection</strong>
                                <div className="d-flex flex-column justify-content-center align-items-start pt-3">
                                    <h2 style={{ color: "#B59104" }} >{todaysTotalCollection}</h2>
                                    <Link to="#" className=" text-decoration-none">
                                        <span class="fw-bold"></span>
                                        <i className="bi bi-arrow-right-short ms-2"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol sm={12} className="mt-3">
                        <CCard>
                            <CCardHeader>
                                <strong>Unsent Orders</strong>
                            </CCardHeader>
                            <CCardBody className="table-responsive">
                                <DataTable
                                    className="tableTopSpace "
                                    columns={columns}
                                    responsive={true}
                                    data={orderList}
                                    progressPending={listLoading}
                                    paginationTotalRows={orderTotalRecord}
                                    onChangePage={handlePageChange}
                                    pagination
                                    paginationComponentOptions={paginationComponentOptions}
                                    customStyles={customStyles}
                                    paginationServer
                                />
                            </CCardBody>
                        </CCard>
                    </CCol>
                    {/* <CCol md={12} sm={12} lg={5}>                            
                            <CCard className="p-4 mt-3">
                                <CCardHeader style={styleheader}>
                                    <strong>Add Customer</strong>
                                </CCardHeader>
                                <CCardBody>
                                    <CForm
                                        className="row g-3 needs-validation"
                                        noValidate
                                        validated={validated}
                                        onSubmit={handleSubmit}
                                        method="post"
                                        encType="multipart/form-data"
                                    >
                                        {/* <h4>Add Customer</h4>  

                                        <CFormLabel className='mb-0 text-start' style={{ marginLeft: "-6px" }}>Name :</CFormLabel>
                                        <CFormInput
                                            placeholder="Name"
                                            className='mt-0 text-start'
                                            name="name"
                                            type="text"
                                            onChange={(e) => {
                                                setName(e.target.value);
                                                validationForm(e.target.name, e.target.value)
                                            }}
                                            value={name}
                                            required
                                        />
                                        <CFormFeedback invalid>Please Enter Name.</CFormFeedback>
                                        {customeNamerError === true ? (
                                            <>
                                                <CFormFeedback className="errorMessage-customer" >
                                                    Please Enter Name.
                                                </CFormFeedback>
                                            </>
                                        ) : null}
                                        <br />

                                        <CFormLabel className='mb-0 text-start' style={{ marginLeft: "-6px" }}> Contact :</CFormLabel>
                                        <CFormInput
                                            type="number"
                                            className='mt-0 text-start'
                                            name="number"
                                            placeholder="Number"
                                            value={contact}
                                            onChange={(e) => {
                                                setContact(e.target.value);
                                                validationForm(e.target.name, e.target.value)
                                            }}
                                            required
                                        />
                                        <CFormFeedback invalid>Please Enter 10 digit Number.</CFormFeedback>
                                        {customerPhoneError === true ? (
                                            <>
                                                <CFormFeedback className="errorMessage-customer" >
                                                    Please Enter 10 digit Number.
                                                </CFormFeedback>
                                            </>
                                        ) : null}


                                        <CCol xs={12} className="m-0" >
                                            <br />
                                            <CButton
                                                color="primary"
                                                className="px-4"
                                                type="submit"
                                            //  disabled={loading}
                                            >
                                                {/* {loading ? "Wait..":"Update"} 
                                                {/* Update 
                                                Submit
                                            </CButton>
                                        </CCol>

                                    </CForm>
                                </CCardBody>
                            </CCard>
                            <br />
                        </CCol> */}
                </CRow>
            </CContainer>
        </div>
    );
}

import { cilTrash } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
    CBreadcrumb,
    CBreadcrumbItem,
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CContainer,
    CFormInput,
    CFormLabel,
    CHeaderDivider,
    CRow,
} from "@coreui/react";
import moment from "moment-js";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { BillAdd, BillDelete, BillView } from "../Helper/outstanding";

const customStyles = {
    cells: {
        style: {
            borderRight: "1px solid #ddd",
        },
    },
};

const ActionOutstanding = () => {
    const [amount, setAmount] = useState();
    const [payMode, setPayMode] = useState();
    const [billData, setBillData] = useState();
    const [billDate, setBillDate] = useState();
    const [note, setNote] = useState();
    const [billcurrentDate, setBillcurrentDate] = useState(
        moment().format("YYYY-MM-DD")
    );
    const [amountErr, setAmountErr] = useState();
    const [billDateErr, setDateErr] = useState();
    const [paymentModeErr, setPaymentModeErr] = useState();
    let { state } = useLocation();
    let orderId = state?.orderId;
    let customerId = state?.customerId;
    let orderDate = state?.orderDate;
    const [balance, setBalance] = useState();
    const userData = useSelector((state) => state.userData);
    let userId = userData.userinfo.userId;
    const navigate = useNavigate();

    useEffect(() => {
        fetchBillView();
    }, []);

    const fetchBillView = () => {
        let payLoadData = {
            userId: userId,
            customerId: customerId,
            orderId: orderId,
        };

        BillView(payLoadData).then((res) => {
            // console.log(res)
            if (res.status === 200) {
                setBillData(res.data);
                setBalance(res.data.remainingAmount);
            }
        });
    };
    const handleAddBill = (e) => {
        e.preventDefault();
        let validate = true;
        let payLoadData = {
            userId: userId,
            customerId: customerId,
            orderId: orderId,
            amount: amount,
            billDate: billcurrentDate,
            paymentMode: "Cash",
            note: note ?? "",
        };
        // console.log(payLoadData);
        if (payLoadData.amount === undefined) {
            validate = false;
            setAmountErr("Please enter amount");
        }

        if (validate) {
            BillAdd(payLoadData).then((res) => {
                console.log(res);
                if (res.status === 200) {
                    swal("Success", res.message, "success").then((ok) => {
                        if (ok) {
                            window.location.reload();
                        }
                    });
                } else {
                    swal("Warning", res.message, "warning");
                }
            });
        }
    };

    const handleDeleteBill = (billId) => {
        let payLoadData = {
            billId: billId,
            customerId: customerId,
            orderId: orderId,
            userId: userId,
        };
        swal({
            title: "Are you sure?",
            text: "Are you sure that you want to delete?",
            icon: "warning",
            dangerMode: true,
            buttons: ["Cancel", "Ok"],
        }).then((willDelete) => {
            if (willDelete) {
                BillDelete(payLoadData).then((res) => {
                    if (res.status === 200) {
                        swal("Success", res.message, "success").then((ok) => {
                            if (ok) {
                                window.location.reload();
                            }
                        });
                    } else {
                        swal("Warning", res.message, "warning");
                    }
                });
            }
        });
    };

    const columns = [
        {
            name: "Sr.No",
            selector: (row, i) => i + 1,
        },
        {
            name: "Bill Amount",
            wrap: true,
            selector: (row) => row.amountPaid,
        },
        {
            name: "Payment Mode",
            selector: (row) => row.paymentMode,
        },

        {
            name: "Action",
            selector: (row) => {
                return (
                    <div className="button-customerorder">
                        <CButton
                            color="primary"
                            variant="outline"
                            className="buttonsOrderPage px-0"
                            onClick={() => handleDeleteBill(row.billId)}
                        >
                            <CIcon icon={cilTrash} size="lg" />
                        </CButton>
                    </div>
                );
            },
        },
    ];
    const paginationComponentOptions = {
        rowsPerPageText: "",
        noRowsPerPage: true,
    };
    return (
        <div>
            <CHeaderDivider />
            <CContainer fluid>
                <CRow>
                    <CCol xs={12}>
                        <h5 className="main-title"> View Last Bills </h5>
                    </CCol>
                    <CCol xs={8}>
                        <CBreadcrumb
                            className="m-0 ms-2"
                            style={{ "--cui-breadcrumb-divider": "'>'" }}
                        >
                            <CBreadcrumbItem>
                                <Link to="/dashboard">Home</Link>
                            </CBreadcrumbItem>
                            {/* <CBreadcrumbItem >
                                <Link to="/customerbilling">Customer Order</Link>
                            </CBreadcrumbItem> */}
                            <CBreadcrumbItem actives>Last Bill</CBreadcrumbItem>
                        </CBreadcrumb>
                    </CCol>
                    <CCol xs={4}>
                        <div className="text-end">
                            {/* <Link to="/customerbilling"> */}
                            <CButton
                                color="primary"
                                size="sm"
                                className="px-4 text-end text-white "
                                type="button"
                                style={{ marginTop: "-52px" }}
                                onClick={() => window.history.back()}
                            >
                                Back
                            </CButton>
                            {/* </Link> */}
                        </div>
                    </CCol>
                </CRow>

                {/* <CCard className="mt-3">
            <CCardHeader>
              <strong>Customer Info : </strong>
            </CCardHeader>
            <CCardBody>
              <CRow className="justify-content-start">
                <CCol md={4} sm={12}>
                  <b className="mx-4">Customer Name</b> :
                  <lable className="">{billData?.customerName}</lable>
                </CCol>
                <CCol md={4} sm={12}>
                  <b className="mx-4">Customer Phone</b> :
                  <lable className="mx-3">{billData?.customerPhone}</lable>
                </CCol>
                <CCol md={4} sm={12}>
                  <b className="mx-4">Balance</b> :
                  <lable className="mx-3">{billData?.remainingAmount}</lable>
                </CCol>
                <CCol md={4} sm={12}>
                  <b className="mx-4">Total Charges</b> :
                  <lable className="mx-3">{billData?.totalCharges}</lable>
                </CCol>
                <CCol md={4} sm={12}>
                  <b className="mx-4">Total Paid</b> :
                  <lable className="mx-3"> {billData?.totalPaid}</lable>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard> */}

                {/* <CRow className="mt-3">
          <CCol md={6} sm={12}>
            <CCard>
              <CCardHeader>
                <strong>Customer Info : </strong>
              </CCardHeader>

              <form className="form p-4">
              <div className="mt-3">
                  <lable>Customer Name </lable>
                  <br />
                  <CFormInput 
                    type="text" 
                    value={billData?.customerName}
                    readOnly
                  />
                </div>

                <div className="mt-3">
                  <lable> Customer Phone</lable>
                  <br />
                  <CFormInput 
                  type="text"
                  value={billData?.customerPhone}
                   />
                </div>

                <div className="mt-3">
                  <lable> Balance</lable>
                  <br />
                  <CFormInput 
                  type="text"
                  value={billData?.remainingAmount}
                   />
                </div>

                <div className="mt-3">
                  <lable> Total Charges </lable>
                  <br />
                  <CFormInput 
                  type="text" 
                  value={billData?.totalCharges}
                  />
                </div>

                <div className="mt-3">
                  <lable>Total Paid </lable>
                  <br />
                  <CFormInput 
                  type="text" 
                   value={billData?.totalPaid}
                  />
                </div>
              </form>
            </CCard>
          </CCol>
        </CRow> */}

                {/* {console.log(billData?.remainingAmount)} */}
                {balance === "0.00" ? (
                    <>
                        <CRow className="mt-3">
                            <CCol md={12} sm={12}>
                                <CCard>
                                    <CCardHeader>
                                        <strong>Bill History </strong>
                                    </CCardHeader>
                                    <CCard>
                                        <CCardBody>
                                            <DataTable
                                                className="tableTopSpace  border border-table"
                                                columns={columns}
                                                responsive={true}
                                                data={billData?.billPaidList}
                                                // pagination
                                                paginationComponentOptions={paginationComponentOptions}
                                                customStyles={customStyles}
                                            // paginationServer
                                            />
                                        </CCardBody>
                                    </CCard>
                                </CCard>
                            </CCol>
                        </CRow>
                    </>
                ) : (
                    <>
                        <CRow className="mt-3">
                            <CCol md={6} sm={12} className="mb-2">
                                <CCard>
                                    <CCardHeader>
                                        <strong>Add Bill </strong>
                                    </CCardHeader>
                                    <form className="form p-4">
                                        <div className="mt-3">
                                            <lable> Unpaid Bill Amount </lable>
                                            <br />
                                            <input
                                                type="text"
                                                value={billData?.remainingAmount}
                                                readOnly
                                                placeholder="Remaining Amount"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="mt-3">
                                            <lable> Bill Date </lable>
                                            <br />
                                            <CFormInput
                                                type="date"
                                                value={billcurrentDate}
                                                onChange={(e) => {
                                                    // setBillDate(e.target.value); setDateErr("")
                                                    if (
                                                        moment(e.target.value).format("YYYY-MM-DD") <=
                                                        moment().format("YYYY-MM-DD")
                                                    ) {
                                                        setBillcurrentDate(e.target.value);
                                                    } else {
                                                        setBillcurrentDate("");
                                                    }
                                                }}
                                                placeholder="Order Date"
                                            />

                                            {/* <span className='text-danger'>{billDateErr}</span> */}
                                        </div>
                                        <div className="mt-3">
                                            <lable> Amount </lable>
                                            <br />

                                            <input
                                                type="text"
                                                onChange={(e) => {
                                                    setAmount(e.target.value);
                                                    setAmountErr("");
                                                }}
                                                placeholder="Enter amount"
                                                value={amount}
                                                className="form-control"
                                            />
                                            <span className="text-danger">{amountErr}</span>
                                        </div>
                                        <div className="mt-3 d-none" >
                                            <lable> Payment Mode </lable>
                                            <br />
                                            <select
                                                name=""
                                                id=""
                                                className="form-select"
                                                onChange={(e) => {
                                                    setPayMode("Cash");
                                                    setPaymentModeErr("");
                                                }}
                                            >
                                                <option value="">Select method</option>
                                                <option value="Cash">Cash</option>
                                                <option value="Card">Card</option>
                                                <option value="UPI">UPI</option>
                                                <option value="net banking">Net Banking</option>
                                                <option value="cheque">Cheque</option>
                                            </select>

                                        </div>
                                        <div className="mt-3">
                                            <lable> Note </lable>
                                            <br />
                                            <textarea
                                                name=""
                                                id=""
                                                cols="30"
                                                rows="3"
                                                className="form-control"
                                                onChange={(e) => setNote(e.target.value)}
                                            ></textarea>
                                        </div>
                                        <div className="mt-3">
                                            <button
                                                className="btn btn-primary"
                                                onClick={handleAddBill}
                                            >
                                                Add Bill
                                            </button>
                                        </div>
                                    </form>
                                </CCard>
                            </CCol>

                            <CCol md={6} sm={12}>
                                <CCard>
                                    <CCardHeader>
                                        <strong>Customer Info : </strong>
                                    </CCardHeader>

                                    <form className="form p-4">
                                        <div className="mt-3">
                                            <lable>Customer Name </lable>
                                            <br />
                                            <CFormInput
                                                type="text"
                                                value={billData?.customerName}
                                                disabled
                                            />
                                        </div>

                                        <div className="mt-3">
                                            <lable> Customer Phone</lable>
                                            <br />
                                            <CFormInput
                                                type="text"
                                                value={billData?.customerPhone}
                                                disabled
                                            />
                                        </div>

                                        <div className="mt-3">
                                            <lable> Balance</lable>
                                            <br />
                                            <CFormInput
                                                type="text"
                                                value={billData?.remainingAmount}
                                                disabled
                                            />
                                        </div>

                                        <div className="mt-3">
                                            <lable> Total Charges </lable>
                                            <br />
                                            <CFormInput
                                                type="text"
                                                value={billData?.totalCharges}
                                                disabled
                                            />
                                        </div>

                                        <div className="mt-3">
                                            <lable>Total Paid </lable>
                                            <br />
                                            <CFormInput
                                                type="text"
                                                value={billData?.totalPaid}
                                                disabled
                                            />
                                        </div>
                                    </form>
                                </CCard>
                            </CCol>
                        </CRow>

                        <CRow className="mt-2">
                            <CCol md={12} sm={12} >
                                <CCard>
                                    <CCardHeader>
                                        <strong>Bill History </strong>
                                    </CCardHeader>
                                    <CCardBody>
                                        <DataTable
                                            className="tableTopSpace  border border-table"
                                            columns={columns}
                                            responsive={true}
                                            data={billData?.billPaidList}
                                            // pagination
                                            paginationComponentOptions={paginationComponentOptions}
                                            customStyles={customStyles}
                                        // paginationServer
                                        />
                                    </CCardBody>
                                </CCard>
                            </CCol>
                        </CRow>
                    </>
                )}
            </CContainer>
        </div>
    );
};

export default ActionOutstanding;

import { useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CForm,
    CFormCheck,
    CFormInput,
    CFormFeedback,
    CFormLabel,
    CFormSelect,
    CFormTextarea,
    CInputGroup,
    CInputGroupText,
    CWidgetStatsF,
    CWidgetStatsB,
    CRow,
    CHeaderDivider,
    CBreadcrumb,
    CBreadcrumbItem,
    CContainer,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilInfo } from "@coreui/icons";
import DataTable, { FilterComponent } from "react-data-table-component";
import { customerBillUnpaidList, outstandingHistory, OutstandingList } from "../Helper/outstanding";
import { useSelector } from "react-redux";
import Select, { components } from "react-select";
import { Routes, Route, useParams } from "react-router-dom";
import { customerListApi } from "../Helper/customer";
import { fetchCustomerListApi } from "../Helper/order";

const customStyles = {
    cells: {
        style: {
            borderRight: "1px solid #ddd",
        },
    },

};

export default function TotalOutstanding() {
    const [data, setData] = useState();
    const [startDate, setstartDate] = useState("");
    const [endDate, setendDate] = useState("");
    const [filterdata, setfilterdata] = useState();
    const [customerId, setCustomerId] = useState("");
    const [totalRows, setTotalRows] = useState();
    const [perPage, setPerPage] = useState(10);
    const userData = useSelector((state) => state.userData);
    const [customerList, setCustomerList] = useState();
    const [listLoading, setListLoading] = useState();
    const [selectedCustomer, setSelectedCustomer] = useState("");

    let navigate = useNavigate();

    const [data2, setData2] = useState();
    const [totalRows2, setTotalRows2] = useState();
    let { customerIdd } = useParams();
    let UserId = userData.userinfo.userId;


    const columns = [
        {
            name: "Sr. No.",
            selector: (row, index) => index + 1,
        },
        {
            name: "Amount Paid",
            selector: (row) => row.amountPaid,
        },
        {
            name: "Bill Date",
            selector: (row) => row.billDate,
        },
        {
            name: "Payment Mode",
            selector: (row) => row.paymentMode,
        },
    ];
    const handlePerRowChange = (page) => {
        if (page === undefined) {
            page = 0;
        }
        var ofs = (page - 1) * 10;
        setListLoading(true)
        outstandingHistory(customerIdd, ofs, UserId).then((res) => {
            setListLoading(false)
            if (res.status === 200) {
                // console.log(res)
                setData(res.data.billPaidList);
                setSelectedCustomer(res.data);
                setTotalRows(res.data.totalBills)
            } else {
                setListLoading(false)
            }
        }).catch(
            setListLoading(false)
        )
    }
    const handlePageChange = (page) => {
        if (page === undefined) {
            page = 0;
        }
        var ofs = (page - 1) * 10;
        outstandingHistory(customerIdd, ofs, UserId).then((res) => {
            setListLoading(false)
            if (res.status === 200) {
                setData(res.data.billPaidList);
                setSelectedCustomer(res.data);
                setTotalRows(res.data.totalBills)
            } else {
                setListLoading(false)
            }
        }).catch(
            setListLoading(false)
        )
    }




    const columns2 = [
        {
            name: "Sr. No.",
            selector: (row, index) => index + 1,
        },
        {
            name: "Order Id",
            selector: (row) => row.orderId,
        },
        {
            name: "Order Amount",
            selector: (row) => row.orderAmount,
        },
        {
            name: "Paid Amount",
            selector: (row) => row.paidAmount,
        },
        {
            name: "Remaining Amount",
            selector: (row) => row.remainingAmount,
        },
        {
            name: "Bill",
            selector: (row) => (
                <>
                    <button className="btn btn-primary" onClick={() =>
                        navigate("/customerbilling/actionoutstanding", {
                            state: {
                                orderId: row.orderId,
                                orderDate: row.orderDate,
                                customerId: row.customerId,
                                customerName: row.customerName,
                            },
                        })
                    }>
                        Pay Bill
                    </button>
                </>
            ),
        },
    ];
    const handlePerRowChange2 = (page) => {
        if (page === undefined) {
            page = 0;
        }
        var ofs = (page - 1) * 10;
        setListLoading(true)
        customerBillUnpaidList(UserId, ofs, customerIdd).then((res) => {
            console.log(res)
            setListLoading(false)
            if (res.status === 200) {
                setData2(res.data);
                setTotalRows2(res.totalRecords)
            } else {
                setListLoading(false)
            }
        }).catch(
            setListLoading(false)
        )
    }
    const handlePageChange2 = (page) => {
        if (page === undefined) {
            page = 0;
        }
        var ofs = (page - 1) * 10;
        customerBillUnpaidList(UserId, ofs, customerIdd).then((res) => {
            console.log(res)
            setListLoading(false)
            if (res.status === 200) {
                setData2(res.data);
                setTotalRows2(res.totalRecords)
            } else {
                setListLoading(false)
            }
        }).catch(
            setListLoading(false)
        )
    }



    useEffect(() => {
        handlePerRowChange(0)
        handlePerRowChange2(0)
    }, [])

    const paginationComponentOptions = {
        rowsPerPageText: "",
        noRowsPerPage: true,
    };

    return (
        <div>
            <CHeaderDivider />
            <CContainer fluid>
                <CRow>
                    <CCol xs={12}>
                        <h5 className="main-title" style={{ marginLeft: "-6px" }}>View</h5>
                    </CCol>
                    <CCol xs={8}>
                        <CBreadcrumb
                            className="m-0 ms-2"
                            style={{ "--cui-breadcrumb-divider": "'>'" }}
                        >
                            <CBreadcrumbItem>
                                <Link to="/dashboard" style={{ marginLeft: "-12px" }}>Home</Link>
                            </CBreadcrumbItem>
                            {/* <CBreadcrumbItem ><Link to="/totaloutstanding">Outstanding</Link></CBreadcrumbItem> */}
                            <CBreadcrumbItem actives>View Details</CBreadcrumbItem>
                        </CBreadcrumb>
                    </CCol>
                    <CCol xs={4}>
                        <div className="text-end">
                            <Link to="/totaloutstanding">
                                <CButton
                                    color="info"
                                    size="sm"
                                    className="px-4 text-end text-white "
                                    type="button"
                                    style={{ marginTop: "-52px" }}
                                >
                                    Back
                                </CButton>
                            </Link>
                        </div>
                    </CCol>
                </CRow>
            </CContainer>



            <CRow className="mt-2">
                <CCol md={12} sm={12}>
                    <CCard>
                        <CCardHeader>
                            <strong>Customer Info : </strong>
                        </CCardHeader>

                        <CRow className="justify-content-start ps-3 pe-3 pt-2 pb-3">
                            <CCol md={4} sm={12}>
                                <div >
                                    <lable>Customer Name </lable>
                                    <br />
                                    <CFormInput
                                        type="text"
                                        value={selectedCustomer?.customerName}
                                        disabled
                                    />
                                </div>
                            </CCol>

                            <CCol md={4} sm={12}>
                                <div >
                                    <lable> Customer Phone</lable>
                                    <br />
                                    <CFormInput
                                        type="text"
                                        value={selectedCustomer?.customerPhone}
                                        disabled
                                    />
                                </div>
                            </CCol>

                            <CCol md={4} sm={12}>
                                <div >
                                    <lable> Outstanding</lable>
                                    <br />
                                    <CFormInput
                                        type="text"
                                        value={selectedCustomer?.outstanding}
                                        disabled
                                    />
                                </div>
                            </CCol>

                            <CCol md={4} sm={12}>
                                <div className="mt-3">
                                    <lable> Total Paid </lable>
                                    <br />
                                    <CFormInput
                                        type="text"
                                        value={selectedCustomer?.paid}
                                        disabled
                                    />
                                </div>
                            </CCol>
                            <CCol md={4} sm={12}>
                                <div className="mt-3">
                                    <lable>Balance</lable>
                                    <br />
                                    <CFormInput
                                        type="text"
                                        value={selectedCustomer?.balance}
                                        disabled
                                    />
                                </div>
                            </CCol>
                        </CRow>
                    </CCard>
                </CCol>
            </CRow>

            {/* <CRow className="justify-content-center">
                <CCol md={12} lg={12}>
                    <CCard className=" mt-2">
                        <CCardHeader>
                            <strong>View</strong>
                        </CCardHeader>
                        <CCardBody>
                            <DataTable
                                className="tableTopSpace  border border-table"
                                columns={columns}
                                data={data}
                                highlightOnHover
                                pagination
                                paginationServer
                                progressPending={listLoading}
                                paginationRowsPerPageOptions={[]}
                                paginationComponentOptions={paginationComponentOptions}
                                paginationTotalRows={totalRows}
                                customStyles={customStyles}
                                onChangePage={handlePageChange}
                            />
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow> */}
            <CRow className="justify-content-center">
                <CCol md={12} lg={12}>
                    <CCard className="mt-2">
                        <CCardHeader>
                            <strong>Unpaid Orders</strong>
                        </CCardHeader>
                        <CCardBody>
                            <DataTable
                                className="tableTopSpace  border border-table"
                                columns={columns2}
                                data={data2}
                                highlightOnHover
                                pagination
                                paginationServer
                                progressPending={listLoading}
                                paginationRowsPerPageOptions={[]}
                                paginationComponentOptions={paginationComponentOptions}
                                paginationTotalRows={totalRows2}
                                customStyles={customStyles}
                                onChangePage={handlePageChange2}
                            />
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </div>
    );
}